$search-refinements-category-name-separator: none;
$search-refinements-category-name-border-top: 1px solid color(border);
$search-refinements-header-border-top--large: $search-refinements-category-name-border-top;
$search-refinements-category-name-font: 500 #{rem-calc(12)} / 1 $font-primary;
$search-refinements-category-name-padding: rem-calc(19 50 19 20);
$search-refinements-padding: rem-calc(0 0 40);
$search-refinements-padding--large: rem-calc(0);
$search-refinements-category-name-margin: rem-calc(0 0 14);
$search-refinements-header-title-font: #{rem-calc(12)}/1 $font-primary;
$search-refinements-header-title-font--large: $search-refinements-header-title-font;
$search-refinements-header-padding: rem-calc(0 15);
$search-refinements-header-padding--large: rem-calc(0 0 24);
$search-refinements-header-title-margin: 0;
$search-refinements-header-title-margin--large: $search-refinements-category-name-padding;
$search-refinements-header-back-and-results-font: #{rem-calc(12)} / normal $font-tertiary;

@import "@lora/05-components/search/search-refinements";