$plp-top-size: full !default;
$plp-slot-top-size: full !default;
$plp-slot-top-margin: rem-calc(0 0 15);
$plp-slot-top-margin--large: rem-calc(0 0 42);
$plp-results-count-font: #{rem-calc(14)} / 1.25 $font-primary;
$plp-results-count-margin: rem-calc(0 17 0 0);
$plp-product-results-padding-top: rem-calc(20);
$plp-product-results-padding-top--large: rem-calc(10);
$plp-tools-margin--large: rem-calc(0 0 10);
$plp-results-view-switcher-padding: rem-calc(0 9);
$plp-sorting-field-text-transform: uppercase;

@import "@lora/04-layout/plp";