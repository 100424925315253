// Item
$breadcrumbs-item-separator: ">";
$breadcrumbs-item-separator-color: color(dark);
$breadcrumbs-item-separator-margin: rem-calc(15);
$breadcrumbs-item-separator-font: #{rem-calc(12)} / 1 $font-primary;

// Link
$breadcrumbs-link-color: color(dark);
$breadcrumbs-link-hover-color: color(primary-active);
$breadcrumbs-link-padding: rem-calc(7 0);

@import "@lora/05-components/breadcrumbs";