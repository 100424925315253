$search-plp-top-banner-background: color(organism-background);
$search-plp-top-banner-category-name-text-transform: initial;
$search-plp-top-banner-results-count-font: #{rem-calc(18)} / 1.25 $font-primary;
$search-plp-top-banner-category-name-margin: rem-calc(24 0 24 25);
$search-plp-top-banner-results-count-text-transform: initial;
$search-plp-top-banner-results-count-display: inline-block;
$search-plp-top-banner-category-name-display: $search-plp-top-banner-results-count-display;
$search-plp-top-banner-category-name-with-category-cover-display: $search-plp-top-banner-results-count-display;
$search-plp-top-banner-text-align: left;
$search-plp-top-banner-category-description-margin: rem-calc(0 0 15 25);
$search-plp-top-banner-results-count-margin: rem-calc(0 5 15);

@import "@lora/05-components/search/search-plp-top-banner";